<script>
import {
  geneticPatternsDataTypeOptions,
  categoriesAnimalDataTypeOptions,
  categoriesComplementaryDataTypeOptions,
  daysOccupationDataTypeOptions,
  mainActivityDataTypeOptions } from '@/utils/enums'
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import gql from 'graphql-tag';
import cloneDeep from 'lodash/cloneDeep';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  props: ['id'],

  data() {
    return {
      loading: false,
      loadingFarms: false,
      loadingModules: false,
      loadingCode: false,
      showSupplementation: false,
      valid: true,
      hasId: false,
      farms: [],
      modules: [],
      categoriesComplementaryFiltred: [],
      
      form: {},

      geneticPatterns: geneticPatternsDataTypeOptions,
      categoriesAnimal: categoriesAnimalDataTypeOptions,
      categoriesComplementary: categoriesComplementaryDataTypeOptions,
      daysOccupationOptions: daysOccupationDataTypeOptions,
      mainActivities: mainActivityDataTypeOptions,
      statuses: [
        { value: 'ATIVO', text: 'Ativo' },
        { value: 'ENCERRADO', text: 'Encerrado' },
      ],

      geolocation: {
        latitude: null,
        longitude: null,
      },

      validations: {
        name: [(v) => !!v || 'Nome é obrigatório'],
        code: [(v) => !!v || 'Identificador é obrigatório'],
        status: [(v) => !!v || 'Situação é obrigatório'],
        farm: [(v) => !!v || 'Fazenda é obrigatório'],
        module: [(v) => !!v || 'Módulo é obrigatório'],
        genetic_pattern: [(v) => !!v || 'Padrão genético é obrigatório'],
        category_animal: [(v) => !!v || 'Categoria animal é obrigatório'],
        main_activity: [(v) => !!v || 'Atividade principal do lote é obrigatório'],
        category_complementary: [(v) => (this.categoriesComplementaryFiltred.length === 0 || !!v) || 'Categoria complementar é obrigatório'],
        supplement_mineral_consumption: [(v) => {
          if (v && this.form.average_batch_weight) {
            const consumeMS = this.form.supplement_mineral_consumption * this.params['MINERAL_MS_PERC']
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_MINERAL_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_feed_consumption: [(v) => {
          if (v && this.form.average_batch_weight) {
            const consumeMS = this.form.supplement_feed_consumption * this.params['FEED_MS_PERC']
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_FEED_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_vol_ms: [(v) => {
          if (v && this.form.average_batch_weight) {
            const consumeMS = this.form.supplement_vol_consumption * (v / 100)
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_VOL_LIMIT'] || 'Qtd. de MS excedeu o limite'
          }
          return true
        }],

        supplement_enabled: [(v) => {
          if (v && this.form.average_batch_weight) {
            let consumeMS = 0
            if (this.form.supplement_mineral_consumption) {
              consumeMS += this.form.supplement_mineral_consumption * this.params['MINERAL_MS_PERC']
            }
            if (this.form.supplement_feed_consumption) {
              consumeMS += this.form.supplement_feed_consumption * this.params['FEED_MS_PERC']
            }
            if (this.form.supplement_vol_consumption && this.form.supplement_vol_ms) {
              consumeMS += this.form.supplement_vol_consumption * this.form.supplement_vol_ms / 100
            }
            const percent = (consumeMS / this.form.average_batch_weight) * 100
            return percent <= this.params['SUPPLEMENT_MS_TOTAL_LIMIT'] || 'Consumo de matéria seca excedeu o limite do peso do animal.'
          }
          return true
        }]
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['isManager']),
  },

  async created() {
    await this.fetchFarms()

    if (!this.id) {
      // new
      if (this.farms[0]) {
        if (this.$route.query.farmId) {
          this.$set(this.form, 'farm_id', this.$route.query.farmId)
        } else {
          this.$set(this.form, 'farm_id', this.farms[0].value)
        }
        this.populateModules()
      }
    } else {
      this.hasId = true
      // edit
      const form = await this.fetch();
      this.form = cloneDeep(form);
      this.form.day_occupation = `${form.day_occupation}`
      this.$set(this.form, 'farm_id', form.farm_id)
      await this.populateModules()
      this.$set(this.form, 'module_id', form.module_id)
      this.showCategoryComplementary()
      this.$set(this.form, 'category_complementary', form.category_complementary)
    }
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return false;
      if (!this.id) this.insert();
      else this.update();
    },

    getFormSave(formParam) {
      const form = cloneDeep(formParam)
      delete form.farm_id
      return form
    },

    changeSupplementEnabled () {
      if (!this.form.supplement_enabled) {
        this.form.supplement_mineral_consumption = null
        this.form.supplement_mineral_pb = null
        this.form.supplement_mineral_ndt = null
        this.form.supplement_feed_consumption = null
        this.form.supplement_feed_pb = null
        this.form.supplement_feed_ndt = null
        this.form.supplement_feed_fdn = null
        this.form.supplement_vol_consumption = null
        this.form.supplement_vol_ms = null
        this.form.supplement_vol_pb = null
        this.form.supplement_vol_em = null
        this.form.supplement_vol_fdn = null
      }
    },

    checkCategoryComplementarySelectItems (item) {
      if (item.value === 'FEMEA_+24_PARIDA_VAZIA' || item.value === 'FEMEA_+24_SOLTEIRA_PRENHA') {
          return true
        }
      return false
    },

    showCategoryComplementary() {
      this.categoriesComplementaryFiltred = this.categoriesComplementary.filter((item) => {
        return item.category === this.form.category_animal
      })
      if (!this.categoriesComplementaryFiltred.lenght) {
        this.$set(this.form, 'category_complementary', null)
      }
    },

    async insert() {
      this.loading = true;
      try {
        const form = this.getFormSave(this.form)
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($batch: batches_insert_input!) {
              insert_batches_one(object: $batch) {
                id
              }
            }
          `,
          variables: {
            batch: form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro criado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.updateFarm()
        this.$router.push(`/lotes/${result.data.insert_batches_one.id}`);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async update() {
      this.loading = true;
      try {
        const form = this.getFormSave(this.form);
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $batch: batches_set_input!) {
              update_batches_by_pk(pk_columns: { id: $id }, _set: $batch) {
                id
              }
            }
          `,
          variables: {
            id: this.id,
            batch: form,
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Registro alterado com sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.$router.push(`/lotes/${this.id}`);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },

    async updateFarm() {
      try {
        const farm = {
          batches_count: this.form.code
        }
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid!, $farm: farms_set_input!) {
              update_farms_by_pk(pk_columns: { id: $id }, _set: $farm) {
                id
              }
            }
          `,
          variables: {
            id: this.form.farm_id,
            farm: farm,
          },
        });
      }catch (e) {
        console.log(e)
      }
    },

    async fetch() {
      try {
        const result = await this.$apollo.query({
          query: gql`
            query batches($id: uuid!) {
              batches: batches_by_pk(id: $id) {
                code
                name
                status
                customer_id
                genetic_pattern
                category_animal
                category_complementary
                day_occupation
                supplement_mineral_consumption
                supplement_mineral_pb
                supplement_mineral_ndt 
                supplement_feed_consumption
                supplement_feed_pb
                supplement_feed_ndt
                supplement_feed_fdn
                supplement_vol_consumption
                supplement_vol_ms
                supplement_vol_pb
                supplement_vol_em
                supplement_vol_fdn
                supplement_enabled
                main_activity
                module {
                  farm_id
                  id
                }
              }
            }
          `,
          variables: {
            id: this.id,
          },
          fetchPolicy: 'no-cache',
        });
        const data = result.data.batches
        data.farm_id = data.module.farm_id
        data.module_id = data.module.id
        delete data.module
        delete data.__typename
        return data;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchFarms() {
      this.loadingFarms = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query farms {
              farms(
                order_by: [{ name: asc }]
              ) {
                id
                name
                desactivated
                customer{
                  id
                  name
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
        });
        this.loadingFarms = false
        this.farms = result.data.farms.map(item => ({value: item.id, text: item.name, ...item}));
      } catch (e) {
        console.error(e);
      }
      this.loadingFarms = false
    },

    farmFilter (item, queryText) {
      const textOne = item?.name?.toLowerCase()
      const textTwo = item?.customer?.name?.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },

    async populateModules() {
      this.form.customer_id = this.farms.find(item => item.value === this.form.farm_id).customer.id
      let modulesBatches = (await this.fetchModulesAndBatches());
      this.modules = (modulesBatches.modules).map((item) => ({
        value: item.id,
        text: item.name,
        disabled: (modulesBatches.batches.some(e => e.module_id === item.id) && this.form.status == "ATIVO")
      }));
      this.loadingCode = true
      let code = await this.fetchBatchesCount()
      if (!this.form.code) {
        code = code +1
        this.form.code = code.toString()
      }
      this.loadingCode = false
    },

    async fetchModulesAndBatches() {
      this.loadingModules = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query modules ($farmId: uuid!) {
              modules(
                where: {
                  farm_id: { _eq: $farmId }
                },
                order_by: [{ name: asc }]
              ) {
                id
                name
              },
              batches(
                where: {
                  status: { _eq: "ATIVO"} }
              ) {
                module_id,
                status
              }
          }`,
          variables: {
            farmId: this.form.farm_id
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingModules = false
        return result.data;
      } catch (e) {
        console.error(e);
      }
      this.loadingModules = false
    },

    checkUser() {
      if (!this.isManager) {
        if (this.form && this.form.farm_id) {
          let farm = {}
          for (let index = 0; index < this.farms.length; index++ ) {
            if (this.farms[index].id === this.form.farm_id) {
              farm = this.farms[index]
              break
            }
          }
          if (farm.desactivated) {
            return true
          } else {
            return false
          }
        }
      } else {
        return false
      }
    },

    async fetchBatchesCount() {
      this.loadingModules = true
      try {
        const result = await this.$apollo.query({
          query: gql`
            query maxBatchCode($farmId: uuid!) {
              batches_aggregate(
                where: { module: { farm_id: { _eq: $farmId } }, code: { _iregex: "^[0-9]+$" } }
              ) {
                aggregate {
                  max {
                    code
                  }
                }
              }
            }
          `,
          variables: {
            farmId: this.form.farm_id,
          },
          fetchPolicy: 'no-cache',
        });
        this.loadingModules = false
        if (result.data && result.data.batches_aggregate.aggregate.max.code) {
          return Number(result.data.batches_aggregate.aggregate.max.code)
        }  else {
          return 0
        }
      } catch (e) {
        console.error(e);
      }
      this.loadingModules = false
    },

  },
}

</script>

<template>
  <div>
    <h1 class="mb-5">{{ id ? 'Edição de lote' : 'Criação de lote' }}</h1>

    <v-card class="pa-5">
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="save">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.code"
                :rules="validations.code"
                :loading="loadingCode"
                label="Identificador do lote"
                :disabled="isManager? false: true"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="form.name"
                :rules="validations.name"
                label="Descrição"
                outlined
                persistent-placeholder
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.status"
                :rules="validations.status"
                :items="statuses"
                label="Situação"
                outlined
                persistent-placeholder
                @input="populateModules"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="form.farm_id"
                :items="farms"
                :loading="loadingFarms"
                :rules="validations.farm"
                :disabled="hasId"
                :filter="farmFilter"
                label="Fazenda"
                outlined
                persistent-placeholder
                @input="populateModules"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.customer" v-html="data.item.customer.name"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="form.module_id"
                :items="modules"
                :rules="validations.module"
                :disabled="!form.farm_id || hasId"
                :loading="loadingModules"
                label="Módulo que será alocado"
                outlined
                persistent-placeholder
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete
                v-model="form.main_activity"
                :items="mainActivities"
                :rules="validations.main_activity"
                label="Atividade principal do lote"
                outlined
                persistent-placeholder
              />
            </v-col>
          </v-row>

          <div class="d-flex">
            <v-spacer />
            <v-btn color="primary" plain class="mr-2" to="/lotes">Cancelar</v-btn>
            <v-btn color="success" :loading="loading" :disabled="checkUser()" @click="save"> Salvar </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
